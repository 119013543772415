import React from 'react';

const Purchased = () => {
  return (
    <div>
      <h2 className="text-3xl font-bold text-[#313131]">Obrigado</h2>
      <p>Aqui você pode listar os planos disponíveis...</p>
    </div>
  );
};

export default Purchased;
